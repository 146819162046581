<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 2.75C15.5 2.28587 15.3156 1.84076 14.9874 1.51257C14.6592 1.18438 14.2141 1 13.75 1H6.87844C6.73047 1.00048 6.58569 0.956969 6.4625 0.875003L5.59375 0.294066C5.30607 0.101695 4.96763 -0.000673924 4.62156 3.3389e-06H2.25C1.78587 3.3389e-06 1.34075 0.184378 1.01256 0.512567C0.684374 0.840755 0.5 1.28587 0.5 1.75V3.25C0.5 3.31631 0.526339 3.3799 0.573223 3.42678C0.620107 3.47366 0.683696 3.5 0.75 3.5H15.25C15.3163 3.5 15.3799 3.47366 15.4268 3.42678C15.4737 3.3799 15.5 3.31631 15.5 3.25V2.75ZM0.5 10.25C0.5 10.7141 0.684374 11.1593 1.01256 11.4874C1.34075 11.8156 1.78587 12 2.25 12H13.75C14.2141 12 14.6592 11.8156 14.9874 11.4874C15.3156 11.1593 15.5 10.7141 15.5 10.25V4.75C15.5 4.6837 15.4737 4.62011 15.4268 4.57323C15.3799 4.52634 15.3163 4.5 15.25 4.5H0.75C0.683696 4.5 0.620107 4.52634 0.573223 4.57323C0.526339 4.62011 0.5 4.6837 0.5 4.75V10.25Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3b82f6'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '12'
    }
  }
}
</script>
